import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

const useTranslateFormErrors = (errors, touched, setFieldTouched, validationSchema) => {
    useEffect(() => {
        Object.keys(errors).forEach((fieldName) => {
            if (Object.keys(touched).includes(fieldName)) {
                setFieldTouched(fieldName);
            }
        });
    }, [validationSchema]);
};

const WithTranslateFormErrors = ({
    errors,
    touched,
    setFieldTouched,
    validationSchema,
    children,
}) => {
    useTranslateFormErrors(errors, touched, setFieldTouched, validationSchema);
    return <>{children}</>;
};

WithTranslateFormErrors.propTypes = {
    form: PropTypes.object,
};

export default WithTranslateFormErrors;
