import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useMemo} from 'react';
import {useUser} from '../../provider/UserProvider';
import yup, {yupRules, YupTypes} from '../../utils/yup';
import {EmailInput, PasswordInput, CgvCheckBox, MajorCheckBox} from '../common/input';
import {useTranslation} from 'next-i18next';
import WithTranslateFormErrors from '../../utils/useTranslateFormErrors';
import {Box, Checkbox} from '@mui/material';
import {FullWidthButton} from '../common/button';
import {logEvent} from '../../utils/tag';

type Inputs = Pick<YupTypes, 'email' | 'password' | 'cgv' | 'major'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const initialValues = {
    email: '',
    password: '',
    cgv: false,
    major: false,
};

export default function RegisterForm() {
    const i18n = useTranslation('common');
    const {createGoogleUser} = useUser();

    const validationSchema = useMemo(() => {
        const {email, password, cgv, major} = yupRules(i18n);
        const validationSchema = yup.object().shape({email, password, cgv, major});
        return validationSchema;
    }, [i18n]);

    async function register(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        try {
            const result = await createGoogleUser(values.email, values.password);
            logEvent('sign_up');
        } catch (e) {
            if (e.code == 'auth/email-already-in-use') {
                setErrors({email: i18n.t('error.email_already_used')});
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={register}
        >
            {(formik: Props) => (
                <WithTranslateFormErrors
                    errors={formik.errors}
                    touched={formik.touched}
                    setFieldTouched={formik.setFieldTouched}
                    validationSchema={validationSchema}
                >
                    <form id="register" autoComplete="on" onSubmit={formik.handleSubmit}>
                        <Box display={'flex'} flexDirection="row" justifyContent="center">
                            <Box display={'flex'} flexDirection="column" alignItems="center">
                                <EmailInput
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.email && Boolean(formik.errors.email)
                                    }
                                    helperText={formik.touched.email && formik.errors.email}
                                    tips={true}
                                />
                                <PasswordInput
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password &&
                                        Boolean(formik.errors.password)
                                    }
                                    helperText={
                                        formik.touched.password && formik.errors.password
                                    }
                                    autoComplete={'new-password'}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            formik.handleSubmit();
                                        }
                                    }}
                                />
                                <MajorCheckBox
                                    label={i18n.t('label.major')}
                                    control={<Checkbox checked={formik.values.major} />}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.major && formik.errors.major}
                                />
                                <CgvCheckBox
                                    label={i18n.t('label.cgv')}
                                    control={<Checkbox checked={formik.values.cgv} />}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.cgv && formik.errors.cgv}
                                />
                                {/* <ConfirmPasswordInput
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.passwordConfirm &&
                                    Boolean(formik.errors.passwordConfirm)
                                }
                                helperText={
                                    formik.touched.passwordConfirm &&
                                    formik.errors.passwordConfirm
                                }
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        formik.handleSubmit();
                                    }
                                }}
                            /> */}
                            </Box>
                        </Box>
                        <FullWidthButton
                            sx={{
                                marginTop: '50px',
                                width: '100%',
                                borderRadius: '0px',
                                textTransform: 'none',
                            }}
                            onClick={() => formik.handleSubmit()}
                            disabled={!formik.isValid}
                        >
                            {i18n.t('action.create_account')}
                        </FullWidthButton>
                    </form>
                </WithTranslateFormErrors>
            )}
        </Formik>
    );
}
